/* Settings container */
.settings-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally centers the content */
    justify-content: center; /* Vertically centers the content */
    padding: 40px;
    background-color: #121212;
    color: #e0e0e0;
    min-height: 100vh; /* Ensures it spans the full viewport height */
    box-sizing: border-box; /* Ensures padding is included in height calculations */
  }
  
  /* Main title styling */
  .settings-title {
    color: #1CA733;
    font-size: 32px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
  }
  
  /* Subtitle styling */
  .settings-subtitle {
    font-size: 18px;
    color: #cccccc;
    text-align: center;
    max-width: 600px; /* Limit line-length */
    margin-bottom: 40px;
    line-height: 1.4;
  }
  
  /* Settings section styling */
  .settings-section {
    background-color: #1d1d1d;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    color: #e0e0e0;
    width: 100%;
    max-width: 500px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
  }
  
  .settings-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  }
  
  .settings-section h3 {
    color: #1ca733;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  /* Form input and button styling */
  .settings-section input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid #333;
    background-color: #2c2c2c;
    color: #e0e0e0;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .settings-section input:focus {
    border-color: #a0e4b0;
  }
  
  .settings-section button {
    width: 100%;
    padding: 12px;
    background-color: #1CA733;
    color: #121212;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-size: 16px;
  }
  
  .settings-section button:hover {
    background-color: #1ca733;
    transform: translateY(-3px);
  }
  
  /* Checkbox styling for notifications */
  .settings-section label {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #e0e0e0;
    margin-bottom: 10px;
  }
  
  .settings-section input[type='checkbox'] {
    margin-right: 10px;
    transform: scale(1.2); /* Make checkbox slightly larger */
  }
  
  /* Profile field styling */
  .profile-field {
    margin-bottom: 20px;
  }
  
  .profile-field label {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #1ca733;
    margin-bottom: 8px;
  }
  
  .profile-field span {
    color: #e0e0e0;
    background-color: #1d1d1d;
    padding: 10px;
    border-radius: 8px;
  }
  
  .profile-field input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid #333;
    background-color: #2c2c2c;
    color: #e0e0e0;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .profile-field input:focus {
    border-color: #a0e4b0;
  }
  
  .profile-field p {
    margin: 0;
    color: #e0e0e0;
    background-color: #2c2c2c;
    padding: 12px;
    border-radius: 8px;
  }
  
  .profile-field button {
    width: 100%;
    padding: 12px;
    background-color: #1CA733;
    color: #121212;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 8px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-size: 16px;
  }
  
  .profile-field button:hover {
    background-color: #1ca733;
    transform: translateY(-3px);
  }
  