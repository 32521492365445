/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark background overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    background: #121212; /* Dark theme */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(28, 167, 51, 0.8);
    width: 350px; /* Adjust width */
    text-align: center;
    position: relative;
}

/* Close Button */
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #1CA733;
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
}

/* Non-editable fields */
.non-editable {
    text-align: center;
    padding: 15px;
    margin-bottom: 15px;
}

.non-editable p {
    margin: 8px 0;
    color: #ddd;
    font-size: 18px;
}

.non-editable p strong {
    color: #1CA733;
}

/* Input Fields */
.modal-content label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    color: #1CA733;
    text-align: left;
    background-color: transparent;
    border: none;
    padding: 0px;
}

.modal-content input,
.modal-content select {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #1CA733;
    border-radius: 5px;
    background: #1e1e1e;
    color: white;
    font-size: 14px;
    outline: none;
    transition: 0.3s ease-in-out;
    margin-top: 0;
}

/* Input focus effect */
.modal-content input:focus,
.modal-content select:focus {
    border-color: #24d854;
    box-shadow: 0 0 5px rgba(28, 167, 51, 0.8);
}

/* Buttons */
.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.save-button,
.close-button {
    flex: 1;
    padding: 12px;
    margin: 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
}

.save-button,
.close-button {
    background: none;
    border: 1px solid #1CA733;
    color: #1CA733;
}

.close-button:hover,
.save-button:hover {
    background-color: #1CA733;
    color: white;
}

.delete-button {
    background-color: #d9534f; /* Red color */
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100%; /* Full width */
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: #c9302c;
  }


  /* Mobile Optimization */
@media (max-width: 600px) {
    .modal-content {
        width: 90%; /* Expand modal width for small screens */
        max-width: 350px;
        padding: 15px;
    }

    .modal-content label {
        font-size: 18px;
    }

    .modal-content input,
    .modal-content select {
        font-size: 18px;
        padding: 10px;
    }

    .modal-buttons {
        gap: 10px;
    }

    .save-button,
    .close-button,
    .delete-button {
        width: 100%;
        padding: 10px;
        font-size: 18px;
    }

    .non-editable p {
        font-size: 18px;
    }
}