/* General Page Styling */
.tos-page {
    padding: 2rem 4rem;
    font-family: 'Inter', Arial, sans-serif;
    line-height: 1.8;
    color: #d4d4d4;
    background-color: #111;
  }
  
  /* Headings */
  .tos-page h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #ffffff;
    text-align: center;
  }
  
  .tos-page h3 {
    font-size: 1.75rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #1CA733;
  }
  
  .tos-page h4 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    color: #1CA733;
  }
  
  /* Paragraphs */
  .tos-page p {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  
  /* Lists */
  .tos-page ul {
    margin-left: 3rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  
  .tos-page li {
    margin-bottom: 1.25rem;
  }
  
  /* Links */
  .tos-page a {
    color: #1CA733;
    text-decoration: none;
    font-weight: bold;
  }
  
  .tos-page a:hover {
    text-decoration: underline;
  }

/* Mobile-Specific Adjustments */
@media (max-width: 768px) {
    .tos-page {
      padding: 1.5rem 1rem; /* Reduce padding for smaller screens */
    }
  
    .tos-page h2 {
      font-size: 2rem; /* Reduce heading size */
      margin-bottom: 1rem; /* Adjust spacing below the heading */
    }
  
    .tos-page h3 {
      font-size: 1.5rem; /* Adjust section heading size */
      margin-top: 1.5rem; /* Reduce spacing above headings */
      margin-bottom: 0.75rem; /* Reduce spacing below headings */
    }
  
    .tos-page h4 {
      font-size: 1.25rem; /* Adjust subsection heading size */
      margin-top: 1rem; /* Reduce spacing above subsections */
      margin-bottom: 0.5rem; /* Reduce spacing below subsections */
    }
  
    .tos-page p {
      font-size: 1.125rem; /* Slightly smaller text for paragraphs */
      margin-bottom: 1rem; /* Keep consistent spacing */
    }
  
    .tos-page ul {
      margin-left: 1.5rem; /* Reduce indentation on mobile */
      font-size: 1.125rem; /* Reduce text size for lists */
    }
  
    .tos-page li {
      margin-bottom: 1rem; /* Adjust list item spacing */
    }
  
    .tos-page a {
      font-size: 1rem; /* Adjust link size */
    }
  }
  
  /* Tablet-Specific Adjustments */
  @media (max-width: 1024px) {
    .tos-page {
      padding: 1.5rem 2rem; /* Slightly reduce padding for tablets */
    }
  
    .tos-page h2 {
      font-size: 2.25rem; /* Slightly smaller heading size */
    }
  
    .tos-page h3 {
      font-size: 1.625rem; /* Adjust section heading size */
    }
  
    .tos-page h4 {
      font-size: 1.375rem; /* Adjust subsection heading size */
    }
  
    .tos-page ul {
      margin-left: 2rem; /* Adjust list indentation for tablets */
    }
  }