/* Edge Section */
.edge-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;  /* Left-align text */
    padding: 250px 20px;
    gap: 40px; /* Space between text and image */
}

.edge-content {
    max-width: 50%;
    text-align: left; /* Ensure text is left-aligned */
}

.edge-content h2 {
    font-size: 42px;
    font-weight: bold;
    color: #1CA733; /* Feature color */
    margin-bottom: 20px;
}

.edge-content p {
    font-size: 20px;
    color: #e0e0e0;
    margin-bottom: 30px;
}

.edge-button {
    padding: 12px 30px;
    background-color: #1CA733;
    color: #121212;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.edge-button:hover {
    background-color: #1CA733;
}

.feature-card img, .pricing-card img {
    width: 80px; /* Adjust width as needed */
    height: auto; /* Keep aspect ratio */
    margin-bottom: 20px; /* Space between image and text */
}

/* video formating */
.edge-video {
    width: 100%;
    max-width: 50%;
    margin-top: 20px;
    position: relative;
    padding-top: 25.56%; /* 16:9 Aspect Ratio */
}

.edge-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}



/* Mobile Adjustments */
@media (max-width: 768px) {
    .edge-section {
        flex-direction: column; /* Stack content vertically */
        padding: 50px 20px; /* Reduce padding */
        gap: 20px; /* Less space between text and image */
        align-items: center;
    }

    .edge-content {
        max-width: 100%;
        text-align: center; /* Center-align text for mobile */
    }

    .edge-content h2 {
        font-size: 28px; /* Reduce heading size */
    }

    .edge-content p {
        font-size: 16px; /* Reduce paragraph font size */
        margin-bottom: 20px;
    }

    .edge-button {
        font-size: 14px;
        padding: 10px 20px; /* Smaller padding for button */
    }

    .edge-video {
        width: 100%;
        max-width: 90%; /* Adjust for mobile view */
        margin-top: 20px;
        padding-top: 56.25%;
    }
}