/* General Page Styling */
.privacy-page {
    padding: 2rem 4rem; /* Add spacing around the content */
    font-family: 'Inter', Arial, sans-serif; /* Use a modern, clean font */
    line-height: 1.8; /* Improve readability with line spacing */
    color: #d4d4d4; /* Light gray text for better contrast on dark background */
    background-color: #111; /* Match the site's dark background */
  }
  
  /* Headings */
  .privacy-page h2 {
    font-size: 2.5rem; /* Larger title size for prominence */
    margin-bottom: 1.5rem; /* Space below the title */
    color: #ffffff; /* Bright white for the main heading */
    text-align: center; /* Center-align the main title */
  }
  
  .privacy-page h3 {
    font-size: 1.75rem; /* Slightly smaller for section headings */
    margin-top: 2rem; /* Space above section headings */
    margin-bottom: 1rem; /* Space below section headings */
    color: #1CA733; /* Vibrant green to highlight sections */
  }
  
  .privacy-page h4 {
    font-size: 1.5rem; /* Subsection heading size */
    margin-top: 1.5rem; /* Space above subsection headings */
    margin-bottom: 0.75rem; /* Space below subsection headings */
    color: #1CA733; /* Softer green for subsections */
  }
  
  /* Paragraphs */
  .privacy-page p {
    margin-bottom: 1rem; /* Add spacing between paragraphs */
    font-size: 1.25rem; /* Standard text size for readability */
  }
  
  /* Lists */
  .privacy-page ul {
    margin-left: 3rem; /* Indent the list */
    margin-bottom: 1rem; /* Space below the list */
    font-size: 1.25rem; /* Standard text size for readability */
  }
  
  .privacy-page li {
    margin-bottom: 1.25rem; /* Add space between list items */
    color: #d4d4d4; /* Same as paragraph text */
  }
  
  /* Links */
  .privacy-page a {
    color: #1CA733; /* Bright green for links */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Highlight links */
  }
  
  .privacy-page a:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  
  /* Contact Information */
  .privacy-page strong {
    color: #ffffff; /* Bright white for emphasis */
  }
  
  /* Layout Adjustments for Centered Design */
  .privacy-page-container {
    max-width: 1200px; /* Limit content width */
    margin: 0 auto; /* Center the content */
  }


  /* Mobile-Specific Adjustments */
@media (max-width: 768px) {
    .privacy-page {
      padding: 1.5rem 1rem; /* Further reduce padding for mobile */
    }
  
    .privacy-page h2 {
      font-size: 1.75rem; /* Smaller title for mobile */
    }
  
    .privacy-page h3 {
      font-size: 1.375rem; /* Smaller section heading */
    }
  
    .privacy-page h4 {
      font-size: 1.125rem; /* Smaller subsection heading */
    }
  
    .privacy-page p {
      font-size: 1rem; /* Adjust paragraph text size */
    }
  
    .privacy-page ul {
      margin-left: 1.5rem; /* Further reduce list indentation */
      font-size: 1rem; /* Reduce list text size */
    }
  
    .privacy-page li {
      margin-bottom: 0.75rem; /* Reduce spacing between list items */
    }
  }