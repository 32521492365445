/* Ensure table maintains structure */
#custom-results-table-container {
    max-width: 95%; /* Prevent full width */
    margin: 20px auto; /* Center it */
    padding-left: 20px;
    background-color: #121212;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}


/* Table settings */
.results-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

/* Cell styles */
/* ✅ Fix header row, apply rounded corners */
.custom-results-table th {
    background-color: #1CA733;
    color: white;
    padding: 12px;
    font-weight: bold;
    border-top: none; /* Remove top border */
    border-left: none; /* Remove left border */
    border-right: none; /* Remove right border */
}

/* Header row */
.custom-results-table th {
    background-color: #1CA733;
    color: white;
}

/* Ensure all table rows have a consistent height */
.custom-results-table tr {
    height: 81px; /* Adjust height as needed */
}


.custom-results-table tr:nth-child(even) td {
    background-color: #2b2b2b;
}

.custom-results-table tr:nth-child(odd) td {
    background-color: #1e1e1e;
}

.custom-results-table td.edit-column {
    background-color: #121212 !important; /* Matches the table background */
    border-left: 1px solid rgba(255, 255, 255, 0.1); /* Optional: adds subtle left border */
}

.custom-results-table td.edit-column {
    border: none;
    background: transparent;
    padding: 8px 12px;
    text-align: center;
    white-space: nowrap;
}

.custom-results-table .edit-button {
    background-color: #1CA733;
    color: black;
    border: 0px solid rgba(255, 255, 255, 0.2);
    padding: 6px 14px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

/* Hover effect */
.custom-results-table .edit-button:hover {
    background-color: #178c28;
}

/* FIX: Prevent stretching of the Edit button column */
.custom-results-table td.edit-column {
    width: 80px;
}

/* Mobile Responsive Styling */
@media screen and (max-width: 768px) {

    #custom-results-table-container {
        padding-left: 0px;

    }

    .desktop-view {
        display: none;
    }

    .mobile-view {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 10px;
    }

    .mobile-card {
        background: #1d1d1d;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0 0px 6px rgba(28, 167, 51, 0.8);
        color: white;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .bet-amount {
        font-size: 18px;
        font-weight: bold;
        color: #1CA733;
    }

    .bet-status {
        padding: 5px 10px;
        border-radius: 4px;
        font-weight: bold;
        font-size: 18px;
    }

    .win {
        background: rgba(28, 167, 51, 0.3);
        color: #1CA733;
    }

    .loss {
        background: rgba(211, 47, 47, 0.3);
        color: #d32f2f;
    }

    .mobile-card p {
        font-size: 18px;
        margin: 3px 0;
    }

    .edit-button {
        width: 100%;
        background: #1CA733;
        color: black;
        border: none;
        padding: 8px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
        transition: background-color 0.3s ease;
    }

    .edit-button:hover {
        background: #178c28;
    }
}

/* Desktop view settings */
@media screen and (min-width: 769px) {
    .mobile-view {
        display: none;
    }
}