/* Base game tile CSS */
.gametile {
    border: 1px solid #333;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #202020;
    cursor: pointer;
    position: relative;
    z-index: 2; /* Normal tiles stay below expanded ones */
    height: 280px;
    overflow: hidden; /* Ensure content stays within bounds */
    transform-origin: center; /* Expand from the center */
    transform-style: preserve-3d;
    perspective: 1000px; /* Enable 3D effect */
    /* Include both prefixed and unprefixed transitions */
}


/* Expanded tile - adjusted for Safari compatibility */
.gametile.expanded {
    position: fixed; /* Center the expanded tile */
    top: 50%;
    left: 50%;
    /* Order: prefixed first then standard */
    -webkit-transform: translate(-50%, -50%) scale(1.3) translateZ(0);
    transform: translate(-50%, -50%) scale(1.3) translateZ(0);
    z-index: 1000; /* Bring it above other tiles */
    background-color: #202020;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.8); /* Add shadow */
    border-radius: 10px; /* Optional: Rounded edges */
    height: 460px;
    width: 50%; /* Adjust size */
    max-width: 420px; /* Optional max size */
    overflow: hidden;
    /* Include both prefixed and unprefixed animations */
    -webkit-animation: smooth-expand 0.3s ease-in-out;
    animation: smooth-expand 0.3s ease-in-out;
}


.gametile:hover {
    box-shadow: 0 0px 12px rgba(28, 167, 51, 0.3); /* Subtle shadow for depth */
}

.gametile-gameInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-align: center;
}

.coming-soon-message {
    text-align: center;
    font-size: 18px;
    color: #1CA733;
    margin: auto;
}

.vs-text {
    flex-shrink: 0; /* Prevent the Vs from shrinking */
    font-size: 16px;
    font-weight: bold;
    color: #aaa;
    padding: 0 14px; /* Add small horizontal padding around Vs */
}

.gametile-homeTeam,
.gametile-awayTeam {
    flex-grow: 1; /* Allow the text to grow away from the Vs */
    text-align: right; /* Right-align the home team */
    font-size: 16px;
    font-weight: bold;
    color: #aaa; /* Adjust based on your theme */
}

.dropdown-input {
    position: relative;
    width: 100%;
    background: #333;
    color: #aaa;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    border: 2px solid #1CA733;
}

.dropdown-selected {
    font-size: 16px;
    font-weight: bold;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #333;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
    max-height: 200px; /* Limits scrolling */
    overflow-y: auto;
    border: 2px solid #1CA733;
}

/* scollbar formatting */
.dropdown-menu::-webkit-scrollbar {
    width: 6px; /* Make it thin */
}

.dropdown-menu::-webkit-scrollbar-track {
    background: transparent; /* Make track blend with background */
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 0.5); /* Subtle grey */
    border-radius: 10px; /* Rounded scrollbar */
}

.dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: rgba(200, 200, 200, 0.7); /* Lighter when hovered */
}

/* Firefox specific */
.dropdown-menu {
    scrollbar-width: thin;
    scrollbar-color: rgba(150, 150, 150, 0.5) transparent;
}

.dropdown-item {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

.dropdown-item:hover,
.dropdown-item:active {
    color: #1CA733;
    font-weight: bold;
}

.dropdown-icon {
    transition: transform 0.3s ease-in-out;
    stroke: #1CA733;
}


.stats-table {
    background-color: #1a1a1a;
    border-radius: 8px;
    width: 100%;
  }

/* Table styling */
.stats-table table {
    width: 100%;
    border-collapse: collapse;
}

/* Table rows */
.stats-table tr {
    border-bottom: 1px solid #333;
}

/* Target the same elements but reset the border radius */
.stats-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
  }
  .stats-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 0 !important;
  }

/* Table cells */
.stats-table td {
    /* padding: 8px 12px; */
    font-size: 14px;
    color: #ccc;
    text-align: left;
}

/* Labels */
.stat-label {
    font-weight: 500;
    color: #aaa;
}

/* Values */
.stat-value {
    font-weight: bold;
    text-align: right;
}

.gametile-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.prediction-summary {
    display: flex;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #aaa;
    justify-content: center;
    align-items: center; /* Vertically center the content */
}

.bet-block {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.bet-team-logo img {
    margin-left: -17px;
    height: 60px;
    padding: 10px;
}

.bet-info {
    display: flex;
    padding: 0px 20px 0px 0px; /* to offset gap from the inline 'By'*/
    align-items: center;
    gap: 5px;
    font-size: 1em;
    color: #aaa;
}

.bet-info p {
    margin: 0;
}

.bet-info strong {
    color: #1CA733; /* Highlight the labels */
}

.positive-margin {
    font-weight: bold;
}

.negative-margin {
    font-weight: bold;
}

.game-odds-line {
    display: flex; /* Align items horizontally */
    align-items: center; /* Center vertically */
    gap: 10px; /* Add spacing between elements */
    font-size: 16px; /* Adjust font size */
    color: #CDCDCD; /* Text color */
    justify-content: center; /* center content in odds line element */
}

.game-bookie-logo {
    width: 45px; /* Adjust size */
    height: 45px; /* Keep aspect ratio */
    object-fit: contain; /* Ensure proper fit */
    margin-left: 10px; /* Add space between odds and logo */
    margin-right: 5px;
}


/* flipping tile */


/* Front and Back Faces */
.gametile-front,
.gametile-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Prevent back face from showing */
    -webkit-backface-visibility: hidden; /* Safari compatibility */
    transform-style: preserve-3d;
    overflow: hidden; /* Prevent content overflow */
}

/* Front face */
.gametile-front {
    transform: rotateY(0deg); /* Default state */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px; /* Add padding for neat layout */
    z-index: 1;
}

/* Back face */
.gametile-back {
    transform: rotateY(180deg); /* Initially hidden */
    background-color: #1c1c1c; /* Add background for contrast */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

/* Fix inverted text inside back face */
.gametile-back > * {
    transform: rotateY(180deg); /* Correct text orientation */
}

/* Flipping States */
.gametile.flipped .gametile-front {
    transform: rotateY(-180deg); /* Hide front */
}

.gametile.flipped .gametile-back {
    transform: rotateY(0deg); /* Show back */
}

/* Expanded and Flipped State */
.gametile.expanded.flipped {
    transform: translate(-50%, -50%) scale(1.3) rotateY(180deg); /* Add flip rotation */
}


/* button for edge of card */

/* The right edge button container */
.gametile-right-edge-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; /* Make it span the entire height of the card */
    width: 30px; /* Adjust width for the right edge */
    cursor: pointer;
    background-color: transparent; /* Blend in with the card */
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

/* The icon inside the button */
.game-flip-icon {
    color: #1ca733; /* Hidden by default */
    font-size: 18px; /* Adjust size */
    font-weight: bold;
    transition: color 0.3s ease;
}

/* Hover effect */
.gametile-right-edge-button:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Subtle background highlight */
}

.gametile-right-edge-button:hover .flip-icon {
    color: #1ca733; /* Visible on hover */
}

/* Focus effect for accessibility */
.gametile-right-edge-button:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.2); /* Stronger background on focus */
}

.game-stats-container {
    display: flex;
    justify-content: space-between; /* Place elements on opposite sides */
    align-items: center; /* Center align items vertically */
    padding: 10px 0; /* Add spacing */
}


.high-risk-badge {
    background-color: #b71c1c; /* Red color for high risk */
    color: white;
    border-radius: 10px; /* Rounded corners */
    padding: 5px 10px; /* Adjust padding for size */
    font-size: 14px;
    font-weight: bold; /* Bold text */
    text-align: center; /* Center the text */
    user-select: none; /* Prevent text selection */
    pointer-events: none; /* Disable interactivity */
}

.tiles-container.toggle .gametile:not(.expanded) {
    filter: blur(5px); /* Blur non-expanded tiles */
    pointer-events: none; /* Prevent interactions with blurred tiles */
}

/* Prevent body scroll when a tile is expanded */
.tile-container.blur-background {
    overflow: hidden;
}


/* Blur effect for background when a tile is expanded */
.tiles-wrapper.toggle::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    backdrop-filter: blur(5px); /* Blur effect */
    z-index: 1; /* Place below expanded tile but above other tiles */
}


/* Mobile Adjustments */
@media (max-width: 768px) {

    .gametile {
        height: 300px;         /* Let tile grow with content on small screens */
        overflow: visible;    /* or 'auto' if you want scrolling inside the tile */
      }

    /* Adjust expanded tile */
    .gametile.expanded {
        position: fixed; /* Fix the position relative to the viewport */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        width: 95%; /* Take up full width on mobile */
        max-width: 95%;
        margin: 0; /* No extra margin */
        -webkit-transform: translate(-50%, -50%) scale(1) translateZ(0);
        transform: translate(-50%, -50%) scale(1); /* Center and scale */
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5); /* Stronger shadow for focus */
        height: 500px;
        overflow: hidden; /* stops sidescroll */
        -webkit-animation: smooth-expand 0.3s ease-in-out;
        animation: smooth-expand 0.3s ease-in-out;
    }

    /* Expanded and Flipped State */
    .gametile.expanded.flipped {
        transform: translate(-50%, -50%) scale(1) rotateY(180deg); /* Add flip rotation */
    }

    .team-logo {
        width: 60px;
        padding: 10px;
        object-fit: contain;
        margin: 0 auto;
    }

    /* Adjust the layout inside expanded tiles */
    .gametile-gameInfo {
        padding-top: 10px;
        display: flex;
        justify-content: center; /* Center content horizontally */
        gap: 5px; /* Reduce gap between elements */
    }

    /* Adjust Vs text */
    .vs-text {
        font-size: 14px; /* Scale down the Vs text */
        margin: 0px; /* Reduce horizontal margins */
    }
    
    .bet-team-logo,
    .game-bookie-logo,
    .bet-info,
    .team-logo {
        scale: 1.2;
    }
    .prediction-summary {
        padding: 15px 0px 10px 0px;
    }

    /* Home and Away Team Names */
    .gametile-homeTeam,
    .gametile-awayTeam {
        font-size: 12px; /* Scale down text for smaller screens */
        padding: 0px; /* Add some padding for better spacing */
        text-align: center; /* Center-align text */
    }
    .team-names.away-team-names,
    .team-names.home-team-names {
        margin: 0px;
        padding: 0px;
    }


}