.home-container {
    text-align: center;
    z-index: 3;
}

.load-more-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #339900;
    color: #000000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.no-data-message {
    text-align: center;
    font-size: 1.2rem;
    color: #1CA733; /* Red message to indicate no data */
    margin-top: 20px;
}

.load-more-button:hover {
    background-color: #0056b3;
}

/* for submenu */
.submenu-bar {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #000;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}


.submenu-bar.hidden {
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none; /* Prevents interaction when hidden */
  }

/* Left side (League Dropdown) */
.left-section {
    display: flex;
    align-items: center;
}


.league-dropdown {
    display: flex;
    align-items: center;
}

.dropdown-button {
    background: none;
    border: none;
    color: #1CA733;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
}

.league-logo {
    padding: 10px;
    height: 50px; /* Set a fixed height */
    object-fit: contain; /* Ensures the image scales properly */
}

.dropdown-arrow {
    font-size: 16px;
    font-weight: bold;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: #222;
    border-radius: 5px;
    padding: 5px 0;
    z-index: 10;
}

.dropdown-item {
    padding: 8px 15px;
    cursor: pointer;
    color: white;
}

.dropdown-item:hover {
    background: #333;
}



/* Center section (Game Picks & Player Picks) */
.submenu-items {
    display: flex;
}


.submenu-item {
    font-size: 18px;
    font-weight: bold;
    color: #1CA733; /* Consistent theme */
    cursor: pointer;
    padding: 10px 15px;
    border-bottom: 3px solid transparent;
    transition: color 0.3s, border-bottom 0.3s;
}

.submenu-item.active {
    color: #1CA733; /* Darker highlight for active item */
    border-bottom: 3px solid #1CA733;
}

.filter-options {
    position: relative; /* For dropdown positioning */
}

/* Push Filter Options to the Right */
.filter-trigger {
    margin-left: auto;
    cursor: pointer;
    color: #1CA733;
}

.filter-trigger:hover {
    color: #1CA733;
}

/* Dropdown alignment and style */
.filter-menu-dropdown::before {
    content: '';
    position: absolute;  
    top: -10px;
    right: 10px;
    width: 10px;
    height: 10px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    transform: rotate(45deg);
    z-index: -1;
}

/* Scoped blur for the tiles container */
.tiles-container.tile-blur-background .tile:not(.expanded) {
    filter: blur(5px); /* Blur only non-expanded tiles */
    pointer-events: none; /* Disable interaction with non-expanded tiles */
    transition: filter 0.2s ease; /* Smooth transition */
}


/* Prevent container scrolling when a tile is expanded */
.tiles-container.blur-background {
    overflow: hidden;
}

.last-updated {
    background-color: #333; /* Light background for the bubble */
    color: #1CA733;             /* Text color */
    padding: 8px 12px;       /* Adjust padding for your desired bubble size */
    border-radius: 20px;     /* Fully rounded corners to create a bubble effect */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);  /* Optional: add a subtle shadow */
    width: 30%;
    /* Positioning */
    position: relative;         /* Fixed position relative to the viewport */
    left: 40px;              /* 20px from the left edge */
    top: 20px;               /* 20px from the top edge */
    
    z-index: 1000;           /* Ensure it's on top of other elements */
    font-weight: bold;      /* Adjust font size as needed */
  }


/* mobile view */
@media (max-width: 768px) {
    /* Ensure the filter menu is full width */
    .filter-menu-container {
        width: 100%; /* Full width for mobile */
        padding: 10px; /* Add some spacing around */
        box-sizing: border-box; /* Include padding in width calculation */
    }

    .last-updated {
        width: 80%;
        align-content: center;
        left: 50%;
        transform: translateX(-50%); /* center horizontally */
    }

    .filter-menu {
        display: flex;
        flex-direction: column; /* Stack rows vertically */
        gap: 15px; /* Add spacing between rows */
    }

    .filter-options {
        width: 100%; /* Full width for filter options */
    }

    /* Stack rows vertically */
    .filter-row {
        display: flex;
        flex-direction: column; /* Stack items in a column */
        gap: 10px; /* Add spacing between items */
        width: 100%; /* Ensure it spans the full width */
    }

    /* Ensure filter items are full width */
    .filter-item {
        display: flex;
        flex-direction: column; /* Stack label and input/select */
        width: 100%; /* Full width for each item */
    }

    .filter-item select,
    .filter-item input {
        width: 100%; /* Full width for inputs and selects */
        padding: 10px; /* Add padding for touch devices */
        font-size: 16px; /* Adjust for readability */
        border: 1px solid #333; /* Match theme */
        background-color: #121212; /* Dark theme */
        color: #ddd; /* Text color */
        border-radius: 5px; /* Rounded edges */
    }

    /* Adjust the submit button */
    .filter-submit {
        display: flex;
        justify-content: center; /* Center align the button */
        margin-top: 15px; /* Add spacing from the last row */
    }

    .filter-submit button {
        width: 100%; /* Full width button */
        padding: 10px; /* Comfortable touch area */
        font-size: 16px; /* Readable text size */
        background-color: #1CA733; /* Highlight color */
        color: #121212; /* Text color */
        border: none;
        border-radius: 5px;
        font-weight: bold;
    }
}