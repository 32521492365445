/* Container to center the login form */
.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  height: 100vh;
  background-color: #121212; /* Background matches theme */
}

/* Auth Form Styling */
.auth-form {
  width: 100%;
  max-width: 600px; /* Slightly larger form */
  padding: 30px; /* Increased padding for spacing */
  background-color: #1e1e1e; /* Dark background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* Stronger shadow */
  color: #1CA733;
}

/* Heading */
.auth-form h2 {
  text-align: center;
  font-size: 28px; /* Larger heading */
  margin-bottom: 20px;
}

/* Input Fields */
.auth-form input {
  width: 100%;
  padding: 15px; /* Larger padding */
  margin: 15px 0; /* Increased spacing between fields */
  border: 1px solid #333;
  border-radius: 8px; /* Larger corners for inputs */
  background-color: #333;
  color: #1CA733;
  font-size: 16px; /* Increased font size for readability */
}

.auth-form input:focus {
  outline: none;
  border-color: #1CA733;
  box-shadow: 0 0 8px #1CA733;
}

/* Submit Button */
.auth-form button {
  width: 100%;
  padding: 15px; /* Larger button padding */
  margin: 20px 0;
  background-color: #1CA733;
  color: #121212;
  font-weight: bold;
  border: none;
  border-radius: 8px; /* Match input radius */
  font-size: 18px; /* Larger button text */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.auth-form button:hover {
  background-color: #86c99e;
}

/* Forgot Password Link */
.forgot-password {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password:hover {
  color: #0056b3;
}


/* Responsive Styles for Smaller Screens */
@media (max-width: 768px) {
  .container {
    padding-top: 40px; /* Less padding for smaller screens */
  }

  .auth-form {
    max-width: 95%; /* Expand form to fit screen */
    padding: 15px; /* Reduce internal padding */
  }

  .auth-form h2 {
    font-size: 22px; /* Adjust heading size */
  }

  .auth-form input {
    font-size: 14px; /* Adjust input font size */
    padding: 10px; /* Reduce input padding */
  }

  .auth-form button {
    font-size: 14px; /* Adjust button font size */
    padding: 10px; /* Reduce button padding */
  }

  .auth-form p {
    font-size: 12px; /* Smaller font for links */
  }
}