/* Blur effect for the background content */
.blur-background {
  filter: blur(5px); /* Applies blur to the background */
  transition: filter 0.7s ease; /* Smooth transition for the blur effect */
}

/* Overlay with fade-in and fade-out effects */
.eoi-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.85); /* Matches the navbar background */
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.7s ease; /* Smooth fade effect */
  opacity: 0; /* Default to invisible */
}

.eoi-overlay.fade-in {
  opacity: 1; /* Fully visible when fading in */
}

.eoi-overlay.fade-out {
  opacity: 0; /* Invisible when fading out */
}

/* Popup styling */
.eoi-popup {
  position: relative; /* To position the X button absolutely */
  background-color: #1d1d1d; /* Dark background to match navbar */
  border-radius: 15px;
  width: 650px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 0px 12px rgba(1, 212, 73, 0.3); /* Subtle shadow for depth */
  z-index: 1001; /* Ensure above the overlay */
  border: 1px solid #333; /* Slightly lighter border */
}

.video-container {
  position: relative;
  /* Adjust the width to your preference (e.g., 200px, 30vw, etc.) */
  width: 30vw;

  /* For a 9:16 ratio => 16/9 ~ 1.7778 => 177.78% padding-bottom */
  padding-bottom: 100.78%;
  height: 0;
  overflow: hidden;
  margin: 0 auto; /* center horizontally if needed */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* X Button Styling */
.eoi-close-button {
  position: absolute;
  width: 30px;
  top: 10px;
  right: 10px;
  background: none; /* No background */
  border: none; /* No border */
  font-size: 1.75em; /* Larger font size for visibility */
  color: #1CA733; /* Matches the login/signup button colors */
  cursor: pointer;
  transition: color 0.2s ease, transform 0.2s ease; /* Smooth transition */
}

.eoi-close-button:hover {
  color: #88d497; /* Matches hover color of navbar buttons */
  transform: scale(1.2); /* Slightly enlarge on hover */
}

/* Header Styling */
.eoi-popup h2 {
  color: #1CA733; /* Matches navbar text */
  margin: 20px;
  font-size: 36px; /* Match typography size */
}

.eoi-popup p {
  color: #cdcdcd;
  margin-bottom: 20px;
  font-size: 18px;
}

/* Input Fields Styling */
.eoi-popup form input {
  width: 70%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #333; /* Matches navbar border/button color */
  border-radius: 4px;
  font-size: 1em; /* Consistent font size */
  background-color: #121212; /* Dark gray for contrast */
  color: #1CA733; /* Soft green text */
  caret-color: #1CA733; /* Green text cursor */
  transition: border-color 0.2s ease;
}

.eoi-popup form input:focus {
  border-color: #1CA733; /* Matches hover color of navbar buttons */
  outline: none; /* Remove default outline */
}

/* Submit Button Container */
.eoi-submit-container {
  display: flex;
  justify-content: center; /* Center the button */
  margin-top: 20px; /* Add spacing above the button */
}

/* Submit Button Styling */
.eoi-submit-button {
  padding: 10px 20px;
  background-color: #1CA733; /* Matches navbar signup button */
  color: #121212; /* Black text for contrast */
  border: 2px solid #1CA733; /* Matches navbar buttons */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  font-size: 1em; /* Consistent font size */
  font-weight: bold;
}

.eoi-submit-button:hover {
  background-color: #88d497; /* Matches hover color of navbar signup button */
  border-color: #88d497; /* Match border on hover */
}

.links-component {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  text-align: center; /* Ensures text is centered */
  width: 100%;
  padding: 10px;
}

/* Responsive Adjustments */
@media (max-width: 500px) {
  .eoi-popup {
    width: 90%; /* Adjust width for smaller screens */
    padding: 15px;
  }

  .eoi-popup form input {
    width: 100%; /* Make input fields full-width */
  }

  .eoi-submit-button {
    width: 100%; /* Full-width button */
  }

  .eoi-close-button {
    top: 5px;
    right: 5px;
    font-size: 1.2em; /* Adjust size for smaller screens */
  }

  .video-container {
    width: 50vw;
  }
}
