/* Unified container for the pie chart and its content */
.win-loss-container {
    background-color: #1e1e1e; /* Match other cards */
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

/* Title styling for Win/Loss Ratio */
.win-loss-title {
    color: #1CA733;
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

/* Chart content styling */
.chart-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Tooltip styling */
.recharts-tooltip-wrapper {
    background-color: #333 !important;
    border-radius: 5px;
    color: #fff !important;
    font-size: 0.8rem !important;
}

/* Legend text styling */
.recharts-legend-item-text {
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: capitalize;
    color: #b0b0b0;
}

/* Adjust pie chart label styling */
.recharts-pie-label {
    font-size: 0.8rem;
    font-weight: 400;
    color: #ffffff;
}