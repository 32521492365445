@font-face {
    font-family: 'avenir'; /* Replace with your desired name */
    /* src: url('/public/fonts/niconitalic.otf') format('opentype'); Correct format for OTF */
    src: url('/public/fonts/avenir-next-heavy.ttf') format('truetype'); 
    font-weight: bold;
    font-style: normal;
}


/* Main Hero Section */
.hero {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    text-align: center; /* Ensure text is centered */
    height: 100vh; /* Full viewport height */
    padding: 0 5%;
    position: relative;
    overflow: visible; /* Prevent content overflow */
    margin: 10px;
}
  
  .hero-phone {
    position: absolute; /* Set phone mockup as a background element */
    top: 50%; /* Center vertically */
    right: 5%; /* Position to the right */
    transform: translateY(-50%); /* Adjust vertical centering */
    z-index: 1; /* Ensure it's behind the text */
    width: 30vw;
    max-width: 400px;
    min-width: 200px;
  }
  
  .hero-phone img {
    width: 100%;
    height: auto;
    filter: brightness(0.3) drop-shadow(0 0 80px rgba(1, 212, 73, 0.8));
    
  }
  
  .hero-content {
    position: absolute; /* Position text absolutely */
    left: 6%; /* Align to the left */
    top: 50%;
    transform: translateY(-50%);
    z-index: 2; /* Ensure text is above the phone */
    text-align: left;
    margin: 10px;
  }
  
  .hero-heading {
    font-family: 'avenir', sans-serif;
    font-size: calc(7vw + 1rem);
    font-weight: bold;
    color: #CDCDCD;
    line-height: 1.1;
    margin: 0;
  }
  
  .hero-subtext {
    font-size: calc(1.5vw + 0.5rem);
    color: #CDCDCD;
    margin-top: 2rem;
    margin-bottom: 2rem;
    max-width: 60%;
  }
  
  .hero-button {
    padding: 25px 55px;
    background-color: #1CA733;
    color: #121212;
    border: none;
    border-radius: 15px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-left: 20%; /* Adjust this value to move the button right */
  }
  
  .hero-button:hover {
    background-color: #1CA733;
    transform: translateY(-2px);
  }
  
  /* Mobile Responsive Adjustments */
  @media (max-width: 768px) {
    .hero-phone {
      right: 0;
      top: 30%;
      width: 70%;
    }
  
    .hero-heading {
      font-size: 8vw;
      text-align: center;
    }
  
    .hero-content {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      text-align: center;
      z-index: 2;
    }
  
    .hero-subtext {
      font-size: 1rem;
    }
  
    .hero-button {
      font-size: 0.9rem;
    }
  }


  /* Small Screens (Tablets and Phones) */
/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .hero {
      flex-direction: column; /* Stack content vertically */
      justify-content: flex-start; /* Align content at the top */
      align-items: center; /* Center content horizontally */
      padding: 20px;
      height: auto; /* Allow the hero to grow with content */
  }

  .hero-content {
      position: relative; /* Reset absolute positioning */
      text-align: center; /* Center-align text */
      max-width: 90%; /* Let text take full width */
      margin: 40px 0px 40px; /* Add spacing below content */
      z-index: 2;
  }

  .hero-heading {
      font-size: clamp(24px, 6vw, 48px); /* Scale heading size */
      text-align: center;
      line-height: 1.3;
      margin: 0 0 10px 0; /* Add margin below the heading */
  }

  .hero-subtext {
      font-size: 16px; /* Smaller font size */
      text-align: center;
      margin: 10px 0 15px 0; /* Add margin below the subtext */
      max-width: 100%; /* Limit the width */
  }

  .hero-button {
      font-size: 18px; /* Smaller button size */
      padding: 12px 24px; /* Adjust padding */
      margin-left: 0%;
  }


  .hero-phone {
    position: relative; /* Reset absolute positioning */
    width: 70%; /* Adjust width for smaller screens */
    max-width: 250px; /* Cap the size */
    margin: 20px 0 0 0; /* Add spacing above the phone */
    transform: none; /* Remove unnecessary transformations */
    z-index: 1; /* Place behind content */
}

  .hero-phone img {
      width: 100%; /* Ensure image fits the container */
      height: auto; /* Maintain aspect ratio */
  }
}

/* Medium Screens (Laptops and Small Desktops) */
@media (min-width: 769px) and (max-width: 1200px) {
  .hero-phone {
      width: 35vw; /* Slightly larger for medium screens */
      right: 5%;
      top: 50%;
  }
}

/* Large Screens (Desktop and Ultra-Wide Monitors) */
@media (max-height: 800px) {
  .hero-phone {
      width: 25vw; /* Smaller size for large screens */
      max-width: 300px; /* Cap the size */
      top: 45%;
  }

  .hero-heading {
    font-size: calc(6.5vw + 1rem);
  }
}