.bets-slip {
  background-color: #1b1b1b;
  padding: 1rem;
  border-radius: 8px;
  color: #fff;
}

.bets-slip h2 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  text-align: center;
}

.bet-slip-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2a2a2a;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
}

.selected-bet-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.bet-match {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.teams {
  font-weight: bold;
}

.game-date {
  color: #ccc;
  font-size: 0.9rem;
}

.bet-details {
  display: flex;
  gap: 1rem;
}

.bet-label {
  font-weight: 500;
}

.odds,
.prob {
  color: #bbb;
}

.remove-button {
  background: transparent;
  border: none;
  color: #f66;
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 1rem;
  line-height: 1;
  padding: 0;
}

.remove-button:hover {
  color: #f22;
}

.place-bet-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: #1ca733;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.spread-value.negative {
  color: red;
}

.spread-value.positive {
  color: green;
}

.team-id {
  font-weight: bold;
}


.multi-stats {
  background-color: #2a2a2a;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 4px;
  text-align: center;
}

.multi-stats p {
  margin: 0.25rem 0;
  color: #fff;
}

.stake-odds-inputs {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  justify-content: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-group input {
  width: 120px;
  padding: 0.25rem;
  border-radius: 4px;
  border: 1px solid #1ca733;
  background-color: #2a2a2a;
  color: #1ca733;
  font-size: 18px;
}

.input-group input::placeholder {
  color: #1ca733; 
  font-size: 18px;
}

.place-bet-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.success-message {
  background-color: #1ca733;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 0.5rem;
}

.no-bets-message {
  color: #ccc;       /* Example: gray text */
  font-style: italic; 
  text-align: center; 
  margin: 1rem 0; 
  /* Add any other styling you like */
}


@media screen and (max-width: 768px) {

  /* Keep the bet-slip-item displayed as a column, 
     but we can create two 'rows' inside. */
  .bet-slip-item {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background: #2a2a2a;
    border-radius: 4px;
  }

  /* The first row: Teams (left) and Date (right) */
  .bet-match {
    display: flex;
    justify-content: space-between; /* Teams on left, date on right */
    align-items: center;
    margin-bottom: 0.25rem; /* some spacing below */
  }

  .teams {
    font-size: 1rem;
    font-weight: bold;
  }

  .game-date {
    font-size: 0.8rem;
    color: #ccc;
    text-align: right; /* ensures date is on the right side */
  }

  /* The second row: bet-details can be a single row with smaller text */
  .bet-details {
    display: flex;
    justify-content: space-between; /* spread out odds/prob on the same line */
    gap: 1rem; 
    font-size: 0.9rem;
    color: #bbb;
  }

  .bet-label {
    font-weight: 500;
    color: #fff; /* or keep it #bbb if you prefer */
  }

  .odds,
  .prob {
    color: #bbb;
  }

  /* Adjust the remove button size and position (place it top-right, for instance) */
  .remove-button {
    align-self: flex-end;
    font-size: 1rem;
    margin-top: 0.25rem;
    color: #f66;
  }

  /* For the multi-stats box at the bottom */
  .multi-stats {
    padding: 0.5rem;
    font-size: 0.9rem;
    text-align: center;
  }

  /* Adjust input field sizes */
  .input-group input {
    width: 100px;
    padding: 0.2rem;
    font-size: 16px;
  }

  .input-group input::placeholder {
    font-size: 16px;
  }

  /* Adjust the place bet button or "Add Multi" button */
  .place-bet-btn {
    font-size: 1rem;
    padding: 0.5rem;
    width: 100%; /* make it full width if you want it more prominent */
    margin-top: 0.5rem; /* some spacing above */
  }
}