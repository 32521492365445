/* Container to center the login form */
.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 100px;
    height: 100vh;
    background-color: #121212; /* Background matches theme */
  }

/* Centered container */
.reset-password-container {
    background: #1e1e1e; /* Slightly lighter dark for contrast */
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    padding: 30px;
    max-width: 450px;
    width: 100%;
    text-align: center;
}

/* Heading styling */
.reset-password-container h2 {
    font-size: 1.5rem;
    color: #33cc33; /* Green color for the title */
    margin-bottom: 20px;
}

/* Form input fields */
.reset-password-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #2a2a2a;
    color: #fff;
    font-size: 1rem;
}

/* Input focus effect */
.reset-password-container input:focus {
    border-color: #33cc33; /* Green border on focus */
    outline: none;
}

/* Labels */
.reset-password-container label {
    font-size: 1.1rem;
    color: #ccc;
    text-align: left;
    display: block;
    margin-bottom: 5px;
}

/* Submit button */
.reset-password-container button {
    background-color: #33cc33; /* Green background */
    font-size: 1.2rem;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease-in-out;
    font-weight: bold;
}

.reset-password-container button:hover {
    background-color: #28a428; /* Slightly darker green on hover */
}

/* Link styling */
.reset-password-container a {
    color: #33cc33;
    text-decoration: none;
    padding-top: 10px;
}

.reset-password-container a:hover {
    text-decoration: underline;
}

/* Status message */
.reset-password-container p {
    color: #fff;
    font-size: 0.9rem;
    margin-top: 15px;
}

.reset-link {
    font-size: 1.1rem;
    padding-top: 20px;
}

.reset-button {
    padding-top: 15px;
}