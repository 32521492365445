.total-profit-loss {
    display: flex;
    align-items: center;
    background-color: #1e1e1e; /* Match the other cards */
    border-radius: 15px;
    padding: 0px 15px; /* Compact padding */
    margin: auto;
    width: auto;
    height: auto; /* Automatically adjust height */
    max-width: 300px; /* Constrain max width */
}

.profit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px; /* Space between icon and details */
}

.logo-icon {
    width: 50px; /* Adjust icon size */
    height: 50px;
    object-fit: contain;
}

.profit-details {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between the label and value */
}

.profit-label {
    font-size: 1.5rem; /* Adjust font size */
    color: #1CA733; /* Match the website's green */
    font-weight: bold;
}

.profit-amount {
    font-size: 1.5rem; /* Adjust font size */
    font-weight: bold;
    margin: 0;
}

.profit-amount.positive {
    color: #4caf50; /* Green for positive values */
}

.profit-amount.negative {
    color: #f44336; /* Red for negative values */
}