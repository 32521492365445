.confirm-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border-radius: 8px;
}

.confirm-modal {
    background: #202020;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    overflow: hidden;
}

.confirm-modal h3 {
    color: #1CA733;
    margin-bottom: 10px;
}

.confirm-modal p {
    color: #ccc;
    margin-bottom: 20px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.confirm-button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.confirm-button:hover {
    background-color: darkred;
}

.cancel-button {
    background-color: grey;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.cancel-button:hover {
    background-color: darkgrey;
}