
.tile {
    border: 1px solid #333;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #202020;
    cursor: pointer;
    position: relative;
    z-index: 2; /* Normal tiles stay below expanded ones */
    height: 270px;
    overflow: hidden; /* Ensure content stays within bounds */
    transform-origin: center; /* Expand from the center */

}

.tile-header {
    display: flex;
    justify-content: space-between; /* Position button on the right */
    align-items: center;
    background-color: #2a2a2a; /* Dark grey background */
    padding: 10px 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #333; /* Optional separator */
}

.tile-gameInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    gap: 10px;
    text-align: center;
}

.vs-text {
    flex-shrink: 0; /* Prevent the Vs from shrinking */
    font-size: 16px;
    font-weight: bold;
    color: #aaa;
}

.team-names.home-team-names,
.team-names.away-team-names {
    flex-grow: 1; /* Allow the text to grow away from the Vs */
    /* text-align: right; Right-align the home team */
    padding-right: 10px; /* Add spacing from the Vs */
    font-size: 16px;
    font-weight: bold;
    color: #aaa; /* Adjust based on your theme */
}

/* Left Section: AFL Logo */
.tile-header-left {
    display: flex; /* Flex container for any additional content */
    align-items: center; /* Vertically center the logo */
  }

.tile-header-middle {
    display: flex;
    align-items: center;
}

/* Right Section: Add Button */
.tile-header-right {
    display: flex;
    align-items: center; /* Vertically center the button */
  }

.sport-icon {
    height: 40px; /* Adjust size */
    vertical-align: middle; /* Align with the button */
}


.date-container {
    padding: 4px 8px; /* Padding inside the date box */
    border: 1px solid #999; /* Border color for the box */
    border-radius: 4px; /* Slightly rounded corners */
    background-color: #2b2b2b; /* Background for better contrast */
    color: #999; /* White text for readability */
    font-size: 16px;
    font-weight: bold;
    font-weight: 500;
}

.weather-icon {
    width: 50px;
    height: 50px;
}


.team-logo {
    width: 70px; /* Adjust as needed */
    height: 70px;
    padding: 10px;
    vertical-align: middle; /* Align with text */
    object-fit: contain; /* Ensure the logo fits nicely */
}

.odds-line {
    display: flex; /* Align items horizontally */
    align-items: center; /* Center vertically */
    gap: 10px; /* Add spacing between elements */
    font-size: 16px; /* Adjust font size */
    color: #CDCDCD; /* Text color */
    justify-content: center; /* center content in odds line element */
    margin-top: 10px;
}

.bookie-logo {
    width: 40px; /* Adjust size */
    height: 40px; /* Keep aspect ratio */
    object-fit: contain; /* Ensure proper fit */
    margin-left: 10px; /* Add space between odds and logo */
}

.moolah-icon {
    width: 40px; /* Adjust size */
    height: 40px; /* Keep aspect ratio */
    object-fit: contain; /* Ensure proper fit */
}


.tile-stat-line {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    justify-content: center
} 

.player-name {
    font-weight: bold; /* Emphasize player name */
    color: #CDCDCD; /* Neutral color */
}

.stat-type {
    font-weight: normal;
    color: #999; /* Subdued color for stat type */
}

.line-value {
    font-weight: bold;
    color: #1CA733; /* Highlight color for the stat line */
}

.line-icon {
    width: 20px; /* Adjust icon size */
    height: 20px;
    margin-right: 5px;
    vertical-align: middle; /* Align icon with text */
}


.tile:hover {
    box-shadow: 0 0px 12px rgba(28, 167, 51, 0.3); /* Subtle shadow for depth */
}



/* Expanded tile stays clear and centered */
.tile.expanded {
    position: fixed; /* Center the expanded tile */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.3); /* Center and scale */
    z-index: 1000; /* Bring it above other tiles */
    background-color: #1d1d1d; /* Match tile background */
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.8); /* Add shadow */
    border-radius: 10px; /* Optional: Rounded edges */
    height: 420px;
    width: 50%; /* Adjust size */
    max-width: 420px; /* Optional max size */
    overflow: auto; /* Allow scrolling for long content */
    animation: smooth-expand 0.3s;
}

/* Webkit-prefixed keyframes for Safari */
@-webkit-keyframes smooth-expand {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) scale(1.3);
        transform: translate(-50%, -50%) scale(1.3);
        opacity: 1;
    }
}

/* Standard keyframes */
@keyframes smooth-expand {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) scale(1.3);
        transform: translate(-50%, -50%) scale(1.3);
        opacity: 1;
    }
}

/* Prevent body scroll when a tile is expanded */
.tile-container.blur-background, .gametile-container.blur-background {
    overflow: hidden;
}


.tiles-wrapper {
    position: relative;
    z-index: 5; /* Base z-index for the wrapper */
}

.tiles-container.toggle .tile:not(.expanded),
.tiles-container.toggle .gametile:not(.expanded) {
    filter: blur(5px); /* Blur non-expanded tiles */
    pointer-events: none; /* Prevent interactions with blurred tiles */
}


.tile-button {
    background-color: #1CA733; /* Green color */
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    width: 45px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 6;
}

.team-names {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin: 0 8px; /* Adjust for consistent spacing */
    text-align: center;
}

.home-team-names {
    margin-right: 10px; /* Space between the home name and logo */
}

.away-team-names {
    margin-left: 10px; /* Space between the away name and logo */
}

.flip-toggle {
    position: absolute; /* Place the button within the tile */
    top: 10px; /* Adjust this to control the distance from the top */
    right: 10px; /* Adjust this to control the distance from the left */
    width: 25px;
    height: 25px;
    padding: 5px 10px;
    font-size: 1.3em;
    font-weight: bold;
    background-color: #1CA733;
    color: #121212;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 5; /* Ensure the button stays above the tile content */
}

/* Front-to-back flipping */
.tile.flipped .tile-content {
    display: none;
}

.flip-toggle:hover,
.tile-button:hover {
    background-color: #0056b3; /* Slightly darker for hover */
}

.tile-button.selected {
    background-color: #202020; /* Change the style for selected state */
    color: #01D449;
    border: 1px solid #01D449;
}


/* Blur effect for background when a tile is expanded */
.tiles-wrapper.toggle::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    backdrop-filter: blur(5px); /* Blur effect */
    z-index: 1; /* Place below expanded tile but above other tiles */
}

.tiles-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Dynamic columns */
    margin-top: 40px;
    gap: 20px; /* Space between each tile */
    padding: 0 40px;
    max-width: 100%;
    box-sizing: border-box;
    position: relative; /* Reference point for tiles */
    grid-auto-rows: 1fr; /* Consistent row heights */
    z-index: 2; /* Ensure tiles appear above the blur effect */
}


/* Collapsed tile content */
.tile-content {
    font-size: 0.9em;
    line-height: 1.4;
    max-height: 100px; /* Collapsed height */
    overflow: hidden;
    transition: max-height 0.3s ease;
}

/* Container for extra stats */
.tile-extra {
    padding: 0px 20px;
    border-radius: 10px;
    position: relative;
}

/* Weather container */
.weather-container {
    display: flex;
    align-items: center;
}

.weather-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 8px;
}

.weather-text {
    font-size: 16px;
    color: #fff;
}

/* Risk Level Button */
.risk-level-button {
    display: inline-block;
    position: relative; /* Ensure the tooltip is positioned relative to the button */
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    text-align: center;
    white-space: nowrap;
    min-width: 100px;
    margin-left: auto;
    background-color: rgba(255, 255, 255, 0.8); /* Default opaque background */
}

/* icon for beta testing */
.beta-icon {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    text-align: center;
    white-space: nowrap;
    min-width: 100px;
    background-color: rgba(160, 85, 214, 0.7); /* Default opaque background */
}

/* Risk Levels */
.risk-level-button.high-risk-card {
    background-color: rgba(255, 0, 0, 0.7);
}

.risk-level-button.moderate-risk-card {
    background-color: rgba(255, 255, 0, 0.7);
}

.risk-level-button.low-risk-card {
    background-color: rgba(0, 255, 0, 0.7);
}



/* Tooltip Text */
.tooltip-text {
    visibility: hidden; /* Hidden by default */
    opacity: 0; /* Transparent by default */
    width: 300px; /* Adjust tooltip width */
    background-color: rgba(0, 0, 0, 0.8); /* Dark background for tooltip */
    color: #fff; /* White text for contrast */
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    bottom: -120%; /* Position above the button */
    left: -100%;
    transform: translateX(-50%);
    z-index: 10;
    transition: opacity 0.3s ease-in-out; /* Smooth fade-in/out */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    pointer-events: none; /* Prevent interaction with the tooltip */
}


/* Show Tooltip on Hover */
.risk-level-button:hover .tooltip-text {
    visibility: visible; /* Make tooltip visible */
    opacity: 1; /* Fade-in effect */
}

/* Table cells */
.stats-table td {
    padding: 8px 12px;
    font-size: 14px;
    color: #ccc;
    text-align: left;
}

/* Labels */
.stat-label {
    font-weight: 500;
    color: #aaa;
}

/* Values */
.stat-value {
    font-weight: bold;
    text-align: right;
}

.stat-value.positive {
    color: #1ca733; /* Green for positive values */
}

.stat-value.negative {
    color: #ff4d4d; /* Red for negative values */
}

/* Remove border for the last row */
.stats-table tr:last-child {
    border-bottom: none;
}


/* The right edge button container */
.tile-right-edge-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; /* Make it span the entire height of the card */
    width: 30px; /* Adjust width for the right edge */
    cursor: pointer;
    background-color: transparent; /* Blend in with the card */
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

/* The icon inside the button */
.flip-icon {
    color: #1ca733; /* Hidden by default */
    font-size: 18px; /* Adjust size */
    font-weight: bold;
    transition: color 0.3s ease;
}

/* Hover effect */
.tile-right-edge-button:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Subtle background highlight */
}

.tile-right-edge-button:hover .flip-icon {
    color: #1ca733; /* Visible on hover */
}

/* Focus effect for accessibility */
.tile-right-edge-button:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.2); /* Stronger background on focus */
}



.stats-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 10px;
}

.stat-item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #fff;
}

.stat-label {
    font-weight: 500;
    color: #ccc;
}




/* Scale text size for expanded tiles */
.tile.expanded .tile-header {
    font-size: 0.8em;
}

.tile.flipped .flip-toggle {
    font-size: 0.7em;
    line-height: 1.2;
}

.container-graph {
    padding: 20px;
    width: 100%;
    height: 100%;

}

.recharts-responsive-container {
    width: 100%;
    min-height: 88%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 0px 0px;
    box-sizing: border-box;
    margin: auto auto -15px -20px; /* offset graph to center */
}

.recharts-legend-wrapper {
    padding: 5px 0px 0px 00px;
    margin:auto auto 0px 20px;
}


/* flip animation for bet display tile */

/* Main Tile */
.tile {
    position: relative;
    width: 100%;
    height: 250px; /* Set consistent size */
    transform-style: preserve-3d;
    perspective: 1000px; /* Enable 3D effect */
    /* transition: transform 0.6s ease-in-out; Smooth flip animation */
}

/* Front and Back Faces */
.tile-front,
.tile-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Prevent back face from showing */
    -webkit-backface-visibility: hidden; /* Safari compatibility */
    transform-style: preserve-3d;
    overflow: hidden; /* Prevent content overflow */
}

/* Front face */
.tile-front {
    transform: rotateY(0deg); /* Default state */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px; /* Add padding for neat layout */
    z-index: 1;
}

/* Back face */
.tile-back, .gametile-back {
    transform: rotateY(180deg); /* Initially hidden */
    background-color: #1c1c1c; /* Add background for contrast */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

/* Fix inverted text inside back face */
.tile-back > * {
    transform: rotateY(180deg); /* Correct text orientation */
}

/* Flipping States */
.tile.flipped .tile-front {
    transform: rotateY(-180deg); /* Hide front */
}

.tile.flipped .tile-back {
    transform: rotateY(0deg); /* Show back */
}

/* Expanded State */
.tile.expanded{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.3); /* Center and scale expanded tile */
    z-index: 1000;
    background-color: #1d1d1d;
    border-radius: 10px;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.8);
    width: 420px; /* Adjust size as needed */
    height: 430px;
    overflow: hidden;
    perspective: 1000px; /* Apply 3D perspective to expanded tile */
    /* transition: transform 0.6s ease-in-out; Ensure smooth flip animation */
}

/* Expanded and Flipped State */
.tile.expanded.flipped {
    transform: translate(-50%, -50%) scale(1.3) rotateY(180deg); /* Add flip rotation */
}



/* mobile view */

@media (max-width: 768px) {
    /* Adjust expanded tile */
    .tile.expanded, .gametile.expanded {
        position: fixed; /* Fix the position relative to the viewport */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        width: 95%; /* Take up full width on mobile */
        margin: 0; /* No extra margin */
        transform: translate(-50%, -50%) scale(1); /* Center and scale */
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5); /* Stronger shadow for focus */
        height: 430px;
    }

    /* Expanded and Flipped State */
    .tile.expanded.flipped, .gametile.expanded.flipped {
        transform: translate(-50%, -50%) scale(1) rotateY(180deg); /* Add flip rotation */
    }

    @keyframes smooth-expand {
        0% {
            transform: translate(-50%, -50%) scale(0.7); /* Start centered */
            opacity: 0.5;
        }
        100% {
            transform: translate(-50%, -50%) scale(1); /* Stay centered */
            opacity: 1;
        }
    }

    .bet-team-logo,
    .bookie-logo,
    .moolah-icon,
    .player-name,
    .stat-type,
    .line-icon,
    .line-value,
    .bet-info,
    .vs-text,
    .team-logo {
        scale: 1.2;
        font-size: 120%;
}
}



.dropdown-input {
    margin: 10px 0;
    z-index: 10; /* Ensure it stays above other elements */
}

.dropdown {
    width: 100%;
    padding: 8px;
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #202020;
    color: #fff;
    font-size: 16px;
    outline: none;
    cursor: pointer;
}


.dropdown option {
    background-color: #202020;
    color: #fff;
}
