.multitile {
    border: 1px solid #333;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #202020;
    cursor: pointer;
    position: relative;
    z-index: 2;
    height: 260px;
    overflow: hidden;
    transform-origin: center;
    transform-style: preserve-3d;
    perspective: 1000px;
    max-width: 250px;
}

.multitile:hover {
    box-shadow: 0 0px 12px rgba(28, 167, 51, 0.3);
}

/* Expanded tile */
.multitile.expanded {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.3) translateZ(0);
    z-index: 1000;
    background-color: #202020;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    height: 460px;
    width: 50%;
    max-width: 420px;
    overflow: hidden;
    animation: smooth-expand 0.3s ease-in-out;
}

@keyframes smooth-expand {
    0% {
        transform: translate(-50%, -50%) scale(1) translateZ(0);
    }
    100% {
        transform: translate(-50%, -50%) scale(1.3) translateZ(0);
    }
}

/* If you want flipping behavior */
.multitile.flipped .multitile-front {
    transform: rotateY(-180deg);
}
.multitile.flipped .multitile-back {
    transform: rotateY(0deg);
}

/* Front & Back faces */
.multitile-front,
.multitile-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    overflow: hidden;
}

/* The "front" */
.multitile-front {
    transform: rotateY(0deg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    z-index: 1;
}

/* The "back" */
.multitile-back {
    transform: rotateY(180deg);
    background-color: #1c1c1c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.multitile.expanded.flipped {
    transform: translate(-50%, -50%) scale(1.3) rotateY(180deg);
}

/* Example tile header & content */
.tile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.tile-header-left,
.tile-header-middle,
.tile-header-right {
    display: flex;
    align-items: center;
}


.tile-button.selected {
    color: #fff;
}

.multitile-content {
    padding: 10px;
    /* etc. */
}

/* Example: right-edge flip button */
.multitile-right-edge-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 30px;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}
.flip-icon {
    color: #1ca733;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
}

.bookie-logo,
.game-bookie-logo {
  max-width: 40px;   /* or 50px, whatever fits your design */
  max-height: 40px;
  object-fit: contain; /* keeps aspect ratio */
}


/* Mobile styles */
@media screen and (max-width: 768px) {
    .multitile {
        max-width: 100%;
        height: 300px;
    }

    /* Adjust expanded tile */
    .multitile.expanded {
        position: fixed; /* Fix the position relative to the viewport */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        width: 95%; /* Take up full width on mobile */
        max-width: 95%;
        margin: 0; /* No extra margin */
        transform: translate(-50%, -50%) scale(1); /* Center and scale */
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5); /* Stronger shadow for focus */
        height: 500px;
        overflow: hidden; /* stops sidescroll */
    }

    /* Expanded and Flipped State */
    .multitile.expanded.flipped {
        transform: translate(-50%, -50%) scale(1) rotateY(180deg); /* Add flip rotation */
    }
}