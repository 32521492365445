/* Adjust the container for metrics buttons */
.radio-inputs {
    position: relative; /* Ensure proper placement */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Spread the buttons evenly */
    align-items: center; /* Center buttons vertically */
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    width: 100%; /* Ensure it takes full width */
    font-size: 14px;
    max-width: 600px; /* Prevent the container from becoming too narrow */
}

/* Ensure buttons don't collapse into a narrow column */
.radio-inputs .radio {
    flex: 1 1 auto;
    text-align: center;
    min-width: 100px; /* Prevent buttons from becoming too narrow */
}

/* Hide the input and style the button */
.radio-inputs .radio input {
    display: none;
}

.radio-inputs .radio .name {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 1rem; /* Adjust padding for button size */
    color: rgb(255, 255, 255);
    transition: all 0.15s ease-in-out;
    box-shadow: inset 0 0 0px 1px rgba(0, 0, 0, 0.06);
}

/* Style for the selected button */
.radio-inputs .radio input:checked + .name {
    background-color: #1d1d1d;
    font-weight: 600;
    box-shadow: inset 0 0 0px 2px rgba(0, 0, 0, 0.12);
}

/* Add a container style for the chart and metric buttons */
.container-graph {
    position: relative;
    border: 5px solid #1c1c1c; /* Add a border for the tile */
    max-width: 800px; /* Set a maximum width */
    margin: auto; /* Center the card on the page */
    box-sizing: border-box;
    background-color: #1A1A1A; /* Optional: match the dark theme */
}