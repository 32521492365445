.resultsTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px 20px 30px;
    border-radius: 8px;
    color: #fff;
    overflow-x: hidden;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-radius: 8px;
}

.graphTileBorder {
    padding: 10px 0px 10px 0px;
}

.graphTile,
.mainGraphTile {
    background-color: #222;
    border-radius: 15px;
    width: 100%;
    padding-bottom: 30px;
    font-size: 20px;
}

.emptyTile {
    background-color: #333;
    border-radius: 15px;
    width: 100%;
    padding: 10px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.resultsHeader,
.resultsHeaderButtons {
    gap: 5px;
}

button,
.dateRange button,
.resultsHeader button,
label {
    border-radius: 10px;
    color: #000000;
    background-color:#707070;
    border: 2px solid #444;
    padding: 5px 10px;
    color: #111;
    font-size: 20px;
    cursor: pointer;
}

.offsetGraphs {
    margin: 0px 45px;
    width: 100%;
}

.dateRange,
.resultsHeaderButtons,
.HTButtons,
.HTCheckbox,
.checkboxes {
    display: flex;
    gap: 3px;
}

.tileHeader,
.resultsHeader,
.mainGraphHeader {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #333;
    margin-bottom: "0px";
    text-align: "center";
    color: "white";
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.resultsHeader {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.tileHeader .checkboxes {
    color: "grey";
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.resultsPageMain {
    padding: 50px;
}

/* Rounding corners on table header and footer */
thead th:first-child {
    border-top-left-radius: 15px;
}

thead th:last-child {
    border-top-right-radius: 15px;
}

tbody tr:last-child td:first-child {
    border-bottom-left-radius: 15px;
}

tbody tr:last-child td:last-child {
    border-bottom-right-radius: 15px;
}

th, td {
    padding: 12px;
    text-align: center;
}

th {
    /* background-color: #333; */
    color: #f4f4f4;
}

td {
    background-color: #222;
}


  /* Graph styles */
.graphTileBorder graph-container {
    margin-bottom: 40px;
}

.graph-title {
    text-align: center;
    font-size: 1.5rem;
    color: #cdcdcd;
    margin-bottom: 20px;
}

/* Add padding to the bars and labels */
.recharts-bar-rectangle {
    border-radius: 5px;
}

.recharts-tooltip-wrapper {
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
}

.recharts-text.recharts-cartesian-axis-tick-value {
    white-space: normal !important; /* Ensure text can wrap */
    word-wrap: break-word;  /* Allow text to break and wrap */
}

tspan {
    white-space: wrap;
    text-wrap: auto;
}

/* Ensure text can wrap on ticks */
.recharts-text.recharts-cartesian-axis-tick-value {
    white-space: normal !important; /* Allow text wrapping */
    word-wrap: break-word !important;  /* Break long words */
    text-align: center;
}

/* Ensure wrapping inside tspan for ticks */
tspan {
    white-space: normal !important;
    word-wrap: break-word !important;
}

/* Specific handling for X and Y axis ticks */
.recharts-xAxis .recharts-cartesian-axis-tick text {
    white-space: normal !important;  /* Allow wrapping */
    word-wrap: break-word !important; /* Allow word breaks */
    overflow-wrap: break-word; /* Ensure words break if needed */
}

.recharts-yAxis .recharts-cartesian-axis-tick text {
    white-space: normal !important;
    word-wrap: break-word !important;
}

/* Remove overflow for wrapping in chart container */
.recharts-responsive-container {
    margin: 0px 0px 0px -30px;
    text-wrap: auto;
}

/* Ensure text wraps in tooltip as well */
.recharts-tooltip-wrapper {
    word-wrap: break-word !important;
}

/* Responsive styles */
@media (max-width: 768px) {
    .resultsPageMain {
        padding: 15px;
    }

    .graphsContainer {
        flex-direction: column; /* Stack graphs vertically on small screens */
    }

    .resultsHeaderButtons {
        display: flex;
    }
    
    .graphWrapper {
        width: 100%; /* Full width for small screens */
    }

    td, th {
        padding: 5px 0px 5px 0px;
    }
    
    /* Adjust table for mobile */
    .resultsTable,
    .recharts-responsive-container {
        width: 100%;
        font-size: 10px; /* Smaller font for mobile */
        padding: 0px;
    }

    .graphsContainer .graphWrapper {
        width: 100%;
        max-width: 600px;
        margin: 20px 0;
        text-align: center; /* Center the graph title */
        word-wrap: break-word;
        white-space: normal;
    }

    .offsetGraphs {
        margin: 5px -45px;
        width: 115%;
    }

    .xAxisLabel, .yAxisLabel {
        word-wrap: break-word;
        white-space: normal;
    }

    element.style {
        margin: 10px;
    }

    .recharts-cartesian-axis-title {
        font-size: 12px;
        overflow: hidden;
    }

    .graphTileBorder {
        padding: 0px 0px 10px 0px;
    }

    .graphTile,
    .mainGraphTile {
        font-size: 10px;
        padding-bottom: 5px;
    }

    .graphTileBorder button,
    label {
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .dateRange button {
        padding: 5px;
    }

    .mainGraphHeader {
        flex-direction: column;
    }

    .mainGraphHeaderText {
        display: flex;
        align-items: left;
        width: 100%;
    }

    .mainGraphTile .recharts-responsive-container {
        padding-top: 10px;
    }
    
    .HTButtons {
        display: flex;
        width: 100%;
        padding-bottom: 5px;
        justify-content: space-between;
    }

    .HTButtons button {
        flex: 1;
        padding: 5px;
        
    }

    input[type='checkbox' i] {
        scale: 0.75;
    }

    .HTCheckbox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }


    .checkboxes {
        padding: 10px 0px 5px 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
