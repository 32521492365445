.performance-page {
    padding: 20px;
    color: #ffffff;
    background-color: #121212; /* Match the dark theme */
}

.top-row {
    display: grid;
    grid-template-columns: 1.25fr 1fr 1fr; /* Adjusted width proportions */
    gap: 15px; /* Reduced gap for a more compact layout */
    align-items: center;
    margin-bottom: 20px;
}

.performance-card {
    background-color: #1e1e1e;
    border-radius: 10px; /* Slightly smaller rounding */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 5px;
}

.welcome-box {
    text-align: center;
    font-size: 1rem; /* Slightly smaller font */
}

.unit-bet-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; /* Reduced gap between text and button */
}


.profit-tile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.performance-content {
    display: grid;
    grid-template-columns: 3fr 1fr; /* Larger for the graph, smaller for the pie chart */
    grid-template-rows: auto auto; /* First row for the graph and pie, second for profit */
    gap: 20px;
    align-items: start;
}

/* Weekly Profit/Loss Graph */
.graph-container {
    grid-column: 1 / 2; /* Graph stays in the left column */
    grid-row: 1 / 2; /* Stays in the first row */
    height: 450px; /* Larger height to emphasize */
    width: 100%; /* Fill column */
}

/* Win/Loss Pie Chart */
.pie-chart-container {
    grid-column: 2 / 3; /* Ensure it's in the second column */
    grid-row: 1 / 2; /* Ensure it's in the first row */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Fill the entire grid cell height */
    width: 100%; /* Fill the entire grid cell width */
    background-color: #1e1e1e; /* Optional: Ensure it matches the card design */
    border-radius: 15px; /* Optional: Maintain consistency with the design */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Optional: Consistent elevation */
}


.radar-chart-container {
    text-align: center;
    background-color: #1e1e1e;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden; /* Ensure it doesn't overflow */
}

.radar-chart-container h2 {
    color: #1CA733; /* Website green */
    font-size: 1.5rem;
    padding-top: 20px;
    margin-bottom: 10px;
}

.dateRange {
    display: flex;
    justify-content: space-between;
    gap: 3px;
}

.dateRange button {
    flex: 1;
    height: 90%;
    border-radius: 10px;
    color: #000000;
    background-color:#707070;
    border: 2px solid #444;
    padding: 5px 10px;
    color: #111;
    font-size: 18px;
    cursor: pointer;
}

/* Mobile-Specific Styles */
@media (max-width: 768px) {
    .top-row {
        grid-template-columns: 1fr; /* Stack all cards vertically */
        gap: 10px;
    }

    .performance-content {
        display: flex;
        flex-direction: column; /* Stack content vertically */
        gap: 15px; /* Add gap between sections */
    }

    .performance-card {
        padding: 10px;
        margin-bottom: 10px;
    }

    .graph-container {
        height: auto; /* Let the graph resize for mobile */
    }

    .pie-chart-container {
        height: auto; /* Adjust height dynamically */
    }

    .radar-chart-container {
        height: auto;
    }

    .dateRange button {
        padding: 0px 0px;
        font-size: 14px;
    }
}

/* Extra Small Screens (Phones) */
@media (max-width: 480px) {
    .top-row {
        grid-template-columns: 1fr; /* Fully stack all cards */
    }

    .performance-content {
        flex-direction: column; /* Ensure items stack */
        gap: 15px; /* Add spacing */
    }

    .welcome-box {
        font-size: 0.9rem; /* Slightly smaller text */
    }

    .unit-bet-box {
        font-size: 0.85rem;
    }

    .radar-chart-container h2,
    .pie-chart-container h2 {
        font-size: 1.2rem; /* Slightly smaller heading for mobile */
    }

    .performance-card {
        padding: 8px; /* Compact padding */
    }
}