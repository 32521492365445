.filter-menu {
    position: relative;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.filter-menu.hidden {
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;
}

.filter-trigger {
    font-size: 16px;
    font-weight: bold;
    color: #1CA733;
    cursor: pointer;
    text-align: right;
}

.filter-trigger:hover {
    color: #01D449;
}
 
.filter-options {
    position: absolute;
    top: 30px; /* Adjust for spacing */
    right: 0;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 55px;
    z-index: 1000;
    min-width: 500px; /* Wider for horizontal layout */
}

/* Horizontal row styling */
.filter-row {
    display: flex;
    gap: 20px; /* Space between columns */
    margin-bottom: 15px;
    flex-wrap: wrap; /* Wrap items on smaller screens */
}

.filter-item {
    flex: 1; /* Ensure items adjust evenly */
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; /* Spacing between rows */
}

.filter-item label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #1CA733;
}

.filter-item select,
.filter-item input,
.fmdropdown-input {
    position: relative;
    width: 100%;
    padding: 8px;
    font-size: 14px;
    background-color: #242424bd !important; /* Darker background for inputs */
    color: #3e9c35 !important;
    border: 1px solid #5f5f5f;
    border-radius: 4px;
    transition: border-color 0.3s, background-color 0.3s;
}

.fmfilter-range {
    display: flex;
    gap: 15px;
}

.fmdropdown-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #3e9c35;
}

.fmdropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #222;
    border: 2px solid #5f5f5f;
    border-radius: 4px;
    z-index: 999;
    max-height: 300px;
    overflow-y: auto;
}

/* Individual dropdown items */
.fmdropdown-item {
    padding: 8px;
    color: #3e9c35;
}

.fmdropdown-item:hover {
    background: #333;
}


.filter-item select:focus,
.filter-item input:focus {
    border-color: #3e9c35;
    outline: none;
}

.filter-item select option {
    color: #1CA733; /* Placeholder text color */
    background: #333;
}

.filter-item input::placeholder {
    color: #1CA733;
}


/* scrollbar */
.fmdropdown-menu::-webkit-scrollbar {
    width: 6px;
}

.fmdropdown-menu::-webkit-scrollbar-thumb {
    background: #4caf50;
    border-radius: 5px;
}

.fmdropdown-menu::-webkit-scrollbar-track {
    background: #222;
}

/* Submit Button */
.filter-submit {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.submit-button {
    background-color: #3e9c35;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.submit-button:hover {
    background-color: #72de8bcc;
}

.filter-range {
    display: flex;
    gap: 10px; /* Space between min and max inputs */
}

.filter-range input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #5f5f5f;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .filter-options{
        min-width: auto;
    }
}