.profit-loss-container {
    padding: 1rem;
    background-color: #1e1e1e;
    border-radius: 10px;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); */
    width: 100%;
    height: 100%;
    max-width: none;
}

.graph-title {
    font-size: 1.5rem;
    color: #1CA733; /* Match the graph line */
    margin-bottom: 1rem;
    text-align: left;
    font-weight: 600;
}

.recharts-cartesian-axis-tick line {
    stroke: #b0b0b0;
}

.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
    stroke: #2e2e2e;
}

.recharts-default-tooltip {
    background-color: #333 !important;
    color: #fff !important;
    border-radius: 5px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .profit-loss-container {
        width: 100%; /* Full width for smaller screens */
        padding: 0.5rem;
        height: auto; /* Let height adjust naturally */
    }

    .graph-title {
        font-size: 1.2rem; /* Smaller font for compact screens */
        text-align: center; /* Center title on mobile */
        margin-bottom: 0.5rem; /* Reduce spacing */
    }

    /* Optional: Adjust tooltip size for mobile */
    .recharts-default-tooltip {
        font-size: 0.85rem !important;
    }
}