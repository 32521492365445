/* Plans Page Wrapper */
.plans-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    color: #e0e0e0;
    background-color: #121212;
    width: 100%;
    box-sizing: border-box;
}

/* Page Header */
.plans-page h2 {
    font-size: 36px;
    font-weight: bold;
    color: #1CA733;
    margin-bottom: 40px;
    text-align: center;
}

/* Plans Container */
.plans-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    grid-gap: 20px;
    max-width: 1000px;
    width: 100%;
    box-sizing: border-box;
}

/* Individual Plan Card */
.plan-card {
    background-color: #1d1d1d;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 30px 20px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card:hover {
    transform: scale(1.02);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

/* Day Pass Card spans full width */
.plan-card.wider {
    grid-column: 1 / -1; /* Span all columns */
}

/* Plan Title */
.plan-title {
    font-size: 24px;
    font-weight: bold;
    color: #1CA733;
    margin-bottom: 10px;
}

/* Plan Price */
.plan-price {
    font-size: 18px;
    color: #e0e0e0;
    margin-bottom: 15px;
}

.trial-message {
    font-size: 24px;
    font-weight: bold;
    color: #1CA733;
    margin-bottom: 10px;

}

/* Plan Features */
.plan-features {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    flex-grow: 1; /* Pushes the button to the bottom */
}

.plan-features li {
    margin-bottom: 5px;
    font-size: 16px;
}

/* Subscribe Button */
.subscribe-btn {
    padding: 10px 20px;
    background-color: #1CA733;
    color: #000; /* Text color set to black */
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 150px; /* Fixed width for all buttons */
    align-self: center; /* Center the button within the card */
    margin-top: auto; /* Push the button to the bottom */
}

.subscribe-btn:hover {
    background-color: #88d497;
}

/* Features Container */
.features-container {
    margin-top: 40px;
    width: 100%;
    max-width: 1000px;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.features-container h3 {
    font-size: 28px;
    font-weight: bold;
    color: #1CA733;
    margin-bottom: 20px;
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.feature-item {
    font-size: 16px;
    color: #e0e0e0;
    margin-bottom: 10px;
}

/* Footer */
.footer {
    margin-top: 50px;
    padding: 20px;
    background-color: #1d1d1d;
    text-align: center;
    width: 100%;
    color: #888;
    border-top: 1px solid #333;
}

.footer a {
    color: #1CA733;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

/* Responsive Adjustments */
@media (max-width: 800px) {
    .plans-container {
        grid-template-columns: repeat(2, 1fr); /* Two columns */
    }

    .plan-card.wider {
        grid-column: 1 / -1; /* Span all columns */
    }
}

@media (max-width: 600px) {
    .plans-container {
        grid-template-columns: 1fr; /* Single column */
    }

    .plan-card.wider {
        grid-column: 1 / -1; /* Span all columns */
    }

    /* Adjust Subscribe Button on Mobile */
    .subscribe-btn {
        width: 100%; /* Full width on mobile */
    }
}
