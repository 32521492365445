/* Small devices (phones, less than 600px) */
@media (max-width: 600px) {
    body {
        font-size: 14px; /* Smaller base font for readability */
    }
    h1 {
        font-size: 24px; /* Scale down headings */
    }
    p {
        font-size: 16px; /* Scale down paragraph text */
    }
}

@media (max-width: 600px) {
    section {
        padding: 10px; /* Reduce padding for small screens */
    }
}

/* Reset some default styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto' , sans-serif;
    background-color: #121212; /* Dark background */
    color: #e0e0e0; /* Light text for contrast */
}

/* Ensure the html/body fill the screen height */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Make .App a flex container and take up full viewport height */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

/* Let the main content expand so the footer goes to the bottom */
.content-wrap {
  flex: 1;
}