.radar-chart-container {
  text-align: center;
  background-color: #1e1e1e;
  border-radius: 15px;
  padding: 20px;
  overflow: hidden;
  width: 100%; /* Use full width of parent */
  height: 100%; /* Use full height of parent */
}

.responsive-radar-wrapper {
  width: 100%; /* Use full width */
  height: 100%; /* Allow height to scale */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .radar-chart-container {
    height: 550px; /* Fixed height for mobile */
    padding: 10px;
  }
}