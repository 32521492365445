/* General Footer Styling */
.footer {
    background-color: #111; /* Dark background */
    color: #fff; /* White text */
    padding: 2rem 1rem; /* Add spacing */
    text-align: center; /* Center the content */
    border-top: 1px solid #333; /* Subtle divider line */
  }
  
  /* Content Styling */
  .footer-content {
    margin: 0 auto; /* Center the content */
    line-height: 1.6; /* Improve text spacing */
  }
  
  /* Gambling Help Text */
  .footer-gambling-help {
    font-size: 18px; /* Slightly smaller for subtlety */
    margin-bottom: 1rem; /* Add space below gambling help */
    max-width: 1200px; /* Constrain text width */
    margin: 0 auto; /* Center align text */
  }
  
  /* Footer Lower Section */
  .footer-lower {
    margin-top: 1rem; /* Add space above the lower section */
    font-size: 18px; /* Smaller font for secondary text */
  }
  
  .footer-links {
    margin-top: 0.5rem; /* Add space between text and links */
  }
  
  .footer-link {
    color: #1CA733; /* Bright green for links */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Make links stand out */
  }
  
  .footer-link:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  
  .footer-separator {
    color: #fff; /* Match separator color to text */
    margin: 0 0.5rem; /* Add space around separator */
  }