.accordion-section {
    border-bottom: 1px solid #444;
  }
  
  .accordion-header {
    cursor: pointer;
    padding: 0.75rem 1rem;
    background-color: #202020;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-header h3 {
    margin: 0;
    font-size: 1rem;
  }
  
  .accordion-icon {
    font-size: 1.25rem;
  }
  
  .accordion-content {
    padding: 0.75rem 1rem;
    background-color: #2a2a2a;
  }