/* Ensure these rules have enough specificity to override defaults */
#addBetFormContainer .add-bet-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 350px;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 6px;
    box-sizing: border-box;
  }
  
  #addBetFormContainer .add-bet-form input,
  #addBetFormContainer .add-bet-form select {
    width: 100%;
    padding: 0.75rem;              /* consistent padding */
    border: 1px solid #1CA733;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #222;  /* force background color */
    color: #1CA733;          /* force text color */
    box-sizing: border-box;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  
  #addBetFormContainer .add-bet-form input::placeholder {
    color: #1CA733;
  }

  #addBetFormContainer .add-bet-form button {
    background: none;
    border: 1px solid #1CA733;
    color: #1CA733;
    font-weight: bold;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    box-sizing: border-box;
    margin-top: 12px;
  }
  
  #addBetFormContainer .add-bet-form button:hover {
    background-color: #1CA733;
    color: white;
  }

  /* Mobile styles */
@media (max-width: 600px) {
    #addBetFormContainer .add-bet-form {
      width: 90%;       /* use 90% of the screen width */
      padding: 0.75rem; /* slightly reduce padding */
      gap: 0.75rem;     /* reduce gap between elements */
    }
    
    #addBetFormContainer .add-bet-form input,
    #addBetFormContainer .add-bet-form select {
      font-size: 0.9rem;   /* reduce font size */
      padding: 0.65rem;    /* adjust input padding */
      margin-top: 8px;     /* reduce top margin */
      margin-bottom: 8px;  /* reduce bottom margin */
    }
    
    #addBetFormContainer .add-bet-form button {
      font-size: 0.9rem;   /* reduce button font size */
      padding: 0.65rem;    /* adjust button padding */
    }
  }