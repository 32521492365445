/* Hamburger menu (default position) */
.menu-icon {
    font-size: 24px;
    color: #01D449;
    cursor: pointer;
    display: none; /* Hidden by default, visible on mobile */
}

/* Logo Styling */
.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-icon:hover {
    background-color: #333;
}

.logo img {
    height: 60px;
}

.logo img.logo-full {
    display: block;
}
  
.logo img.logo-icon {
    display: none;
}

/* Auth buttons styling */
.auth-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
}

/* Login and Sign Up Button Styles */
.login-btn {
    background-color: #1CA733;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-decoration: none; /* removes underline */
    color: #000;
}

.signup-btn {
    background-color: #1CA733;
    color: #121212;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-decoration: none; /* removes underline */
}

.login-btn:hover {
    background-color: #01D449;
}
  
.signup-btn:hover {
    background-color: #01D449;
}

/* Mobile-Only and Desktop-Only Classes */
.mobile-only {
    display: none; /* Hidden on desktop */
}
  
.desktop-only {
    display: inline-block; /* Visible on desktop */
}

.signup-btn {
    background-color: #1CA733;
    color: #121212;
}

.signup-btn:hover {
    background-color: #01D449;
}

/* Menu icon for mobile */
.menu-icon {
    font-size: 24px;
    color: #1CA733;
    cursor: pointer;
    display: none; /* Only show on mobile */
}

/* Nav links */
.nav-links {
    display: none; /* Hidden by default */
    gap: 20px;
    z-index: 10000;
}

.nav-links li {
    list-style: none;
}

.nav-links li a {
    text-decoration: none;
    color: #1CA733;
    font-size: 18px;
    padding: 5px 10px;
}

.nav-links li a:hover {
    color: #1CA733;
}

/* User icon styling */
.user-icon {
    font-size: 24px;
    color: #01D449;
    cursor: pointer;
    position: relative;
}

/* Revised User Dropdown Styling */
.user-dropdown {
    position: absolute;
    top: 110%;
    right: 10px;
    background-color: #2c2c2c;
    border: 1px solid #444;
    border-radius: 6px;
    width: 160px;
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    z-index: 100000;
}

.user-dropdown li {
    margin: 0;
    padding: 0;
}

.user-dropdown li a,
.user-dropdown li button {
    display: block;
    width: 100%;
    padding: 12px 16px;
    font-size: 14px;
    color: #f0f0f0;
    background: none;
    border: none;
    text-align: left;
    text-decoration: none;
    transition: background-color 0.2s ease, color 0.2s ease;
    cursor: pointer;
}

.user-dropdown li a:hover,
.user-dropdown li button:hover {
    background-color: #444;
    color: #1CA733;
}

.user-dropdown li:not(:last-child) {
    border-bottom: 1px solid #444;
}

/* Sign Out Button Specifics (if any extra tweaks are needed) */
.sign-out-btn {
    font-weight: bold;
    font-size: 16px;
}

/* Desktop view adjustments */
@media (min-width: 769px) {
    .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background-color: #1d1d1d;
        color: #ffffff;
        padding: 5px 20px;
    }

    .logo {
        flex: 0 0 auto; /* Keeps the logo at its natural size */
        width: 220px;
    }


    .nav-links {
        display: flex;
        justify-content: center;
        gap: 30px;
        list-style: none;
        text-align: center;
    }
    
    .auth-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        list-style: none;
        flex: 0 0 auto;
        width: 220px;
    }
}

/* Mobile View: Switch to icon */
@media (max-width: 768px) {
    .logo img.logo-full {
        display: none; /* Hide the full logo */
    }
  
    .logo img.logo-icon {
        display: block; /* Show the smaller icon */
        height: 50px; /* Adjust icon size as needed */
    }

    /* Hamburger menu on the far left */
    .menu-icon {
        display: block;
        margin-left: 10px; /* Add spacing from the edge */
        z-index: 1001; /* Keep above other elements */
    }
        
    .menu-icon:hover {
        background-color: #333;
    }

    .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        position: relative;
        background-color: #1d1d1d;
    }

    /* Center the logo */
    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000; /* Ensure it appears above other elements */
    }

    .login-btn {
        background-color: transparent;
    }

    /* Adjust login and sign-up buttons */
    .auth-buttons {
        margin-left: auto;
    }

    /* Full-Screen Dropdown Menu for mobile */
    .nav-links {
        display: none;
        position: absolute;
        top: 120%;
        left: 0;
        width: 100vw;
        height: calc(25vh + env(safe-area-inset-bottom));
        background-color: #121212;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .nav-links.active {
        display: flex; /* Show the menu on mobile when active */
    }

    /* Auth Buttons */
    .auth-buttons {
        gap: 5px;
    }

    .mobile-only {
        display: block; /* Show Login button in the hamburger menu */
    }

    .desktop-only {
        display: none; /* Hide Login button from the top-right corner */
    }

    .signup-btn {
        margin-left: auto; /* Keep Sign Up button aligned to the far right */
    }
}
