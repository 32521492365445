/* Spinner Container */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    background-color: #111; /* Optional background for overlay */
  }
  
  /* Spinner Animation */
  .spinner {
    width: 80px;
    height: 80px;
    border: 12px solid #737373; /* Light gray background of the spinner */
    border-top: 12px solid #1CA733; /* Green spinning part */
    border-radius: 50%; /* Make it circular */
    animation: spin 1s linear infinite; /* Spin animation */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }