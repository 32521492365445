.multi-creator-page {
  display: flex;
  height: 75vh; /* or whatever fits your layout */
}

/* Left side: the collapsible picks */
.left-column {
  flex: 3; /* Adjust to taste */
  overflow-y: auto; /* If you want scrolling for a large list */
  overflow-y: auto;        /* still allows scrolling */
  scrollbar-width: none;   /* for Firefox */
  -ms-overflow-style: none;/* for Internet Explorer and Edge */
}

/* For Chrome, Safari, and Opera */
.left-column::-webkit-scrollbar {
  width: 0;
  background: transparent; /* or display: none; */
}

/* Right side: bet slip */
.right-column {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
  background-color: #121212; /* example styling */
}

.generate-button-container {
  display: flex;
  justify-content: center; /* Align to the right */
  margin-top: 1rem;       /* Spacing below */
}

.generate-multi-btn {
  padding: 0.5rem 1rem;
  background-color: #1ca733; 
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}

.generate-multi-btn:hover {
  background-color: #45a049;
}

.drawer-toggle-btn {
  display: none; /* Hide on desktop */
}


@media screen and (max-width: 768px) {

  /* Floating round toggle button on bottom-right */
  .drawer-toggle-btn {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    width: 50px;
    height: 50px;
    background-color: #1ca733;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }

  .multi-creator-page {
    flex-direction: column;
    height: auto; /* Let content define height */
  }
  
  /* Hide the right column (drawer) by default */
  .right-column {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 95%; /* or a value you prefer */
    background-color: #121212;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    overflow-y: auto;
    padding: 1rem;
  }
  
  /* When the drawer is open, slide it in */
  .right-column.open {
    transform: translateX(0);
  }
  
}