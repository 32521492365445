.risk-analysis-container {
    background-color: #1e1e1e;
    border-radius: 15px;
    padding: 10px;
    color: #fff;
    text-align: center;
    width: 100%;
    margin: auto;
}

.risk-analysis-title {
    font-size: 1.5rem;
    color: #1CA733; /* Website green */
    margin-bottom: 10px;
}

.average-prediction {
    font-size: 1.2rem;
    color: #b0b0b0;
    margin-bottom: 20px;
}

.risk-band {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #2b2b2b;
    border-radius: 10px;
    text-align: left;
}

.risk-band-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.high-risk {
    color: #f44336; /* Red for high risk */
}

.medium-risk {
    color: #ffa726; /* Orange for medium risk */
}

.low-risk {
    color: #4caf50; /* Green for low risk */
}

.risk-band p {
    margin: 5px 0;
    color: #b0b0b0;
}