.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* semi-transparent backdrop */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* on top of everything else */
  }
  
  .modal-content {
    background: #1d1d1d; /* or #fff, depending on your theme */
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    min-width: 280px; /* so it doesn't shrink too small */
    text-align: center;
    width: 80%;
    filter: drop-shadow(0 0 5px rgba(1, 212, 73, .8));
  }
  
  .modal-content input {
    width: 60%;
    padding: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 8px;
    border: 1px solid #aaa;
    font-size: 16px;
  }
  
  .modal-buttons {
    display: flex;
    gap: 10px;
    border-radius: 8px;
    justify-content: center;
  }

  .modal-confirm {
    background-color: #1CA733;
    color: #000;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    padding: 8px 16px;
    cursor: pointer;
    margin-right: 10px; /* Some spacing between buttons */
  }
  
  .modal-confirm:hover {
    background-color: #01D449; /* Slightly brighter on hover */
  }
  
  .modal-cancel {
    background-color: #444;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .modal-cancel:hover {
    background-color: #666;
  }