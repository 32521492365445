.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
  
.modal-content {
background-color: #1b1b1b; /* Dark background for a sleek look */
color: #fff;
padding: 2rem;
border-radius: 8px;
width: 600px;
max-width: 90%;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

/* Make sure each label + input is a column */
.form-group {
display: flex;
flex-direction: column;
margin-bottom: 10px;
}

.form-buttons {
grid-column: 1 / -1; /* span across all columns if using grid */
display: flex;
justify-content: center; /* center horizontally */
align-items: center;     /* vertically center in this row */
gap: 1rem;              /* spacing between the buttons */
margin-top: 1rem;       /* optional extra spacing above the buttons */
}

/* Add spacing below the heading */
.modal-content h2 {
    margin-bottom: 1rem;
}


/* Ensure both buttons share the same width and style */
#generate-multi-button,
#close-modal-button{
  width: 150px; /* or any fixed width you prefer */
  text-align: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}

#generate-multi-button {
    background-color: #28a745; /* green */
    color: #fff;
  }
  
#close-modal-button{
background-color: #dc3545; /* red */
color: #fff;
}

.slip-form label {
margin-bottom: 0.5rem;
font-weight: bold;
}

/* Custom Dropdown Base Styles */
.gdropdown-input {
  position: relative;       /* Enables absolute positioning for the dropdown menu */
  background-color: #2c2c2c;  /* Dark background */
  border: 1px solid #333;     /* Subtle border */
  border-radius: 4px;
  padding: 0.5rem;
  color: #fff;
  cursor: pointer;
  width: 100%;              /* Full width of container */
  box-sizing: border-box;
}

.gdropdown-selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.gdropdown-selected svg {
  flex-shrink: 0;
}

.gdropdown-menu {
  top: 100%;                /* Positioned directly below the input */
  left: 0;
  right: 0;
  background-color: #2c2c2c;  /* Match input background */
  border: 1px solid #333;
  border-radius: 4px;
  margin-top: 4px;
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
  box-sizing: border-box;
}

.gdropdown-item {
  padding: 0.5rem;
  color: #1ca733;           /* Accent green for items */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.gdropdown-item:hover {
  background-color: #3a3a3a;
}

/* Optional: If you want a "no options" state */
.gdropdown-item.no-options {
  color: #aaa;
  cursor: default;
}

/* Match the dropdown's dark style for numeric inputs */
.form-group input[type="number"] {
  background-color: #2c2c2c; 
  color: #fff;                /* White text */
  border: 1px solid #333;     /* Subtle border */
  border-radius: 4px;
  padding: 0.5rem;
  width: 100%;                /* Ensures it spans full width like dropdowns */
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {

  .gdropdown-input, .gdropdown-selected, .gdropdown-item {
    font-size: 14px;
    padding: 0.4rem;
  }
  
  .modal-content {
    width: 90%;
    padding: 1rem;
  }
  
  /* Make form fields stack vertically with a smaller gap */
  .slip-form {
    grid-template-columns: 1fr; /* Single column on mobile */
    gap: 0.5rem;
  }
  
  /* Adjust form-group label and input font sizes */
  .slip-form label {
    font-size: 14px;
    margin-bottom: 0.25rem;
  }
  
  
  /* Adjust buttons to be full width and a bit smaller */
  #generate-multi-button,
  #close-modal-button {
    width: 100%;
    font-size: 16px;
    padding: 0.5rem;
  }
  
  .form-buttons {
    flex-direction: column;
    gap: 0.5rem;
  }
}