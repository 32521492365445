.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .add-bet-modal-content {
    background-color: #121212;
    padding: 1em;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    position: relative;

    /* Add these lines to create a green glow */
    box-shadow: 0px 0px 15px rgba(28, 167, 51, 0.8);;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 1.2rem;
    cursor: pointer;
    color: #1CA733;
  }

  .button-container {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: flex-end;
    color: #1CA733;
  }

  .add-bet-button {
    background-color: #1CA733;
    border-radius: 8px;
    border: 0px;
    font-weight: bold;
  }


  .user-results-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  


/* =============== MOBILE STYLES =============== */
@media (max-width: 768px) {
  /* Adjust the modal content for smaller screens */
  .add-bet-modal-content {
    max-width: 90%;        /* Make the modal narrower */
    width: 90%;            /* Ensure it doesn't exceed screen width */
    margin: 1rem;          /* Some breathing room from the screen edges */
    max-height: 90vh;      /* Limit the height so it fits on screen */
    overflow-y: auto;      /* Enable scrolling if content is too tall */
  }

  /* Center the overlay (and thus the modal) on the screen */
  .modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }
}